.section1 {
  background-image: url("./assets/bg.png");
  background-position-y: center;
  background-position-x: center;
  background-size: cover;
  text-align: center;
  border-bottom: #9A5939 solid 20px;
}
.logoBlock {
  padding-top:50px;
}

.heading {
  font-family: "Luckiest Guy", cursive;
  color: #9A5939;
  -webkit-text-stroke: 1px black;
  font-size: 102px;
}
.heading2 {
  font-family: "Luckiest Guy", cursive;
  color: #9A5939;
  -webkit-text-stroke: 1px black;
  font-size: 52px;
}
.text {
  font-family: "Just Another Hand", cursive;
  font-size: 32px;
  letter-spacing: 2px;
}
.subheading {
  font-family: "Just Another Hand", cursive;
  font-size: 62px;
  margin-top: -70px;
  letter-spacing: 2px;
}
.black {
  color:#000;
}
.white {
  color: #fff;
}
.button {
  margin-top:-20px;
  padding: 10px 60px;
  font-family: "Just Another Hand", cursive;
  font-size: 42px;
  color: #fff;
  background-color: #9A5939;
  border-radius: 5px;
  border: 3px solid #000;
}
.pepekid {
  margin-bottom: 100px;
}
.section2 {
  background-image: url("./assets/bg2.png");
  background-position: center;
  background-size: contain;
}

.runningImgBlock {
  padding-top:20px;
  overflow: hidden; /* Initial position off-screen to the left */
  width: 100%; /* Adjust based on your image size */
  height: 250px;
}

@keyframes slideAnimation {
  0%, 100% {
    margin-left: 110%;
  }
  50% {
    margin-left: -20%;
  }
}
@keyframes steppingAnim {
  0% {
    padding-top: 20px;
  }
  5% {
    padding-top: 0px;
  }
  10% {
    padding-top: 20px;
  }
  15% {
    padding-top: 0px;
  }
  20% {
    padding-top: 20px;
  }
  25% {
    padding-top: 0px;
  }
  30% {
    padding-top: 20px;
  }
  35% {
    padding-top: 0px;
  }
  40% {
    padding-top: 20px;
  }
  45% {
    padding-top: 0px;
  }
  50% {
    padding-top: 20px;
  }
  55% {
    padding-top: 0px;
  }
  60% {
    padding-top: 20px;
  }
  65% {
    padding-top: 0px;
  }
  70% {
    padding-top: 20px;
  }
  75% {
    padding-top: 0px;
  }
  80% {
    padding-top: 20px;
  }
  85% {
    padding-top: 0px;
  }
  90% {
    padding-top: 20px;
  }
  95% {
    padding-top: 0px;
  }
  100% {
    padding-top: 20px;
  }
}

.runningImg {
  width: 300px;
  animation: slideAnimation 8s linear infinite, steppingAnim 2s infinite;
}
.section3 {
  padding-top: 50px;
  margin-bottom: 100px;
}
.aboutContent {
  display: flex;
  margin: 0 auto;
  max-width: 1120px;
}
.aboutText {
  font-family: "Just Another Hand", cursive;
  font-size: 42px;
  max-width: 650px;
  margin-top: -30px;
}
.spingpepe{
  width:500px;
  margin-right: 50px;
}
.headingBlock2 {
  display:flex;
}
.smalllogo {
  width: 64px;
  height: 64px;
  padding-top:30px;
  margin-right:20px;
}
.section4 {
  text-align: center;
  background-image: url("./assets/bg3.png");
  background-size: cover;
  padding-top: 200px;
}
.tokenomics {
  padding-bottom: 30px;
}
.tokenomicText {
  padding-top: 10px;
  font-size:42px;
  font-family: "Just Another Hand", cursive;
}
.dexBlock{
  padding-bottom: 100px;
}
.dexImg {
  margin: 10px 5px;
}
.section5 {
  width: 100%;
  height: 700px;
  overflow: hidden;
}

.backgroundVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section6 {
  background-color: #000;

}
.socialBlock {
  max-width: 1120px;
  margin:0 auto;
  display: flex;
}
.joinTextBlock {
  margin-left: 20px;
}
.socialsHeading {
  font-family: "Luckiest Guy", cursive;
  font-size:36px;
  padding-top:30px;
}
.leftDiv {
  display: flex;
}
.rightDiv {
  padding-top:50px;
}
.text2 {
  font-family: "Just Another Hand", cursive;
  font-size: 32px;
  width: 300px;
  margin-top:-20px;
}
.corona {
  margin: 30px 0px;
  width:200px;
  height: 200px;
}
@media only screen and (max-width: 420px) {
  .joinTextBlock {
    margin:0;
    text-align: center;
  }
  .heading{
    font-size: 80px;
  }
  .headingBlock2{
    text-align: center;
    display: block;
  }
  .section2{
    background-size: cover;
  }
  .aboutContent{
    display:block;
  }
  .aboutText {
    text-align: center;
  }
  .spingpepe {
    max-width: 350px;
    margin-right: 0px;
  }
  .leftAbout {
    text-align: center;
  }
  .smalllogo{
    margin-right:0px;
  }
  .socialBlock{
    display: block;
  }
  .leftDiv{
    display: block;
    text-align: center;
  }
  .rightDiv{
    text-align: center;
    padding-bottom: 30px;
  }
  .social {
    max-width: 250px;
  }
}